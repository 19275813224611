import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
    TextField,
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import { styled, keyframes } from '@mui/system';
import { printCustomerReport } from '../Reports/Print/printCustomerReport';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from 'framer-motion';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const CustomerReport = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [damages, setDamages] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            const damagesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
    
            if (user.id) {
                const Orders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedData(Orders);
    
                const filteredDamages = damagesResponse.data.filter(damage =>
                    damage.type === "money back" &&
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setDamages(filteredDamages);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchData();
    }, []);

    const filterData = (query, data) => {
        if (!query) return data;
        return data.filter((d) => 
            d.fullname?.toLowerCase().includes(query.toLowerCase()) // Null-safe
        );
    };
    

    const calculateTotals = (data) => {
        const totalsByCustomer = {};

        data.forEach(order => {
            const customerName = order.fullname;
            let orderTotal = 0;

            order.items.forEach(item => {
                const itemTax = (Number(item.price) * (item.tax / 100)) || 0; // Calculate tax
                const itemTotal = Number(item.price) + itemTax;
                orderTotal += itemTotal;
            });

            const discountPercentage = order.discount || 0; // Default to 0 if undefined or null
            const discountAmount = (orderTotal * discountPercentage) / 100; // Calculate discount amount
            const finalAmount = orderTotal - discountAmount; // Apply discount to the order total

            if (totalsByCustomer[customerName]) {
                totalsByCustomer[customerName] += finalAmount;
            } else {
                totalsByCustomer[customerName] = finalAmount;
            }
        });

        return totalsByCustomer;
    };

    const calculateDamageTotals = () => {
        const damageTotalsByCustomer = {};

        damages.forEach(damage => {
            const customerName = damage.fullname;
            let damageTotal = 0;

            damage.items.forEach(item => {
                damageTotal += Number(item.price) || 0; // Add up damage prices
            });

            if (damageTotalsByCustomer[customerName]) {
                damageTotalsByCustomer[customerName] += damageTotal;
            } else {
                damageTotalsByCustomer[customerName] = damageTotal;
            }
        });

        return damageTotalsByCustomer;
    };

    const uniqueData = Array.from(new Set(fetchedData.map(order => order.fullname)))
        .map(fullname => {
            const order = fetchedData.find(order => order.fullname === fullname);
            return {
                ...order,
                total: calculateTotals(fetchedData)[fullname] || 0
            };
        });

    const dataFiltered = filterData(searchQuery, uniqueData, user);

    const damageTotals = calculateDamageTotals();

    const finalData = dataFiltered.map(order => {
        const orderTotal = order.total;
        const damageTotal = damageTotals[order.fullname] || 0;

        return {
            ...order,
            adjustedTotal: orderTotal - damageTotal
        };
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Sales By Customer
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Report - Sales By Customer
                    </Typography>
                </Box>
            </AnimatedTypography>

            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Sales By Customer
            </AnimatedTypography>*/}
            <Box
           sx={{     display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                   // boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                      marginTop: '10px',
                            },
      }}
            >
                <Typography variant="h4" >

                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections

                    }}
                >
                     <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>SALES BY CUSTOMER</h2>
                    </Box>   
                   
                <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            >
                <Box
                    sx={{
                        display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <IconButton sx={{ padding: 0, mr: 1 }}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{ 
                            width: '250px',
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                        }}
                    />
                    </Box>
                    </motion.div>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                        }}
                    ><Box>
                        <IconButton 
                        onClick={toggleSearch}
                        sx={{
                            display: {xs:'flex',sm:'none'},
                        }}
                        >
                    <SearchIcon />
                </IconButton>
                    </Box>
                    <Box>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <IconButton sx={{ padding: 0, mr: 1 }}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{ 
                            width: '250px',
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                            display:{xs:'none',sm:'flex'}
                        }}
                    />
                    </Box>
                        <Box
                        sx={{
                            mt:{xs:'none',sm:'15px'}


                        }}
                        >
                           <IconButton
                        aria-label="print"
                        onClick={() => printCustomerReport(finalData)}
                    >
                        <PrintIcon />
                    </IconButton> 
                        </Box>
                    
                </Box>
                </Box>

                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}><Typography variant="subtitle2" fontWeight="bold">Customer</Typography></TableCell>
                                <TableCell align="right"><Typography variant="subtitle2" fontWeight="bold">TOTAL</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {finalData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell sx={{ textAlign: 'left', color: '#636363', fontSize: '14px' }}>{order.fullname ? order.fullname : "Direct Customer"}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', color: '#636363', fontSize: '14px' }}>Lkr.{order.adjustedTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}
            <ScrollToTopButton />
        </Box>
    );
};

export default CustomerReport;
