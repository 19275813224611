import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Box,
    Typography,
    Snackbar,
    Alert,
    Grid
} from '@mui/material';
import SupplierTable from './Tables/SupplierTable ';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled, keyframes } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const AddSupplier = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [supplierName, setSupplierName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };



    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);



    const handleSubmit = async () => {
        setLoading(true); // Start loading

        const hasInvalidFields = !supplierName;

        if (hasInvalidFields) {
            handleSnackbar('Please enter the supplierName.', 'error');
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('usertoken', user.id);
        formData.append('supplierName', supplierName);
        formData.append('contactPerson', contactPerson);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('address', address);
        if (image) formData.append('image', image);

        try {
            // Send data to server
            const response = await axios.post('https://admin.bizstor.lk/public/api/save-supplier-data', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            {/**console.log('Data saved successfully:', response.data);*/}

            // Clear input fields after successful save
            setSupplierName('');
            setContactPerson('');
            setEmail('');
            setPhone('');
            setAddress('');
            setImage(null);

            // Show alert on success
            handleSnackbar('Supplier added  successfully!', 'success');
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    return (
        <Box>
                         <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Add Supplier
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Add - Supplier
                    </Typography>
                </Box>
            </AnimatedTypography>

            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Add / Supplier
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: 1,
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h5">
                    
                </Typography>
                {/**<hr style={{ borderTop: '1px solid #6d7081', width: '100%', margin: '10px 0' }} />*/}
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>ADD SUPPLIER</h2>
                    </Box>
<Box
sx={{
    m:2
}}
>
                {/* First Row */}
                <Grid container spacing={2} sx={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Supplier Name"
                            value={supplierName}
                            onChange={(e) => setSupplierName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ marginRight: { xs: 0, sm: '10px' } }} // Adjust margin for responsiveness
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ marginRight: { xs: 0, sm: '10px' } }} // Adjust margin for responsiveness
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                </Grid>

                {/* Second Row */}
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ marginRight: { xs: 0, sm: '10px' } }} // Adjust margin for responsiveness
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            label="Description"
                            value={contactPerson}
                            onChange={(e) => setContactPerson(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                </Grid>

                <Box sx={{ marginBottom: '20px' }}>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        style={{ marginTop: '20px' }}
                    />
                </Box>
                </Box>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            marginTop: '30px',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#356bc4',
                            },
                            marginBottom: { xs: '8px', sm: '0' }
                        }}                    >
                        Add Supplier
                    </Button>
                </div>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white', // Optional: to give a slightly white overlay
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <SupplierTable />
        </Box>
    );
};

export default AddSupplier;
