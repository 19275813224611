import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    TextField,
    IconButton,
    TablePagination
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { monthOptions } from './MonthOption/monthOptions';
import { filterByDateRange } from './MonthOption/filterByDateRange';
import PrintIcon from '@mui/icons-material/Print';
import { printContent } from './Print/SalesbyProduct';
import { styled, keyframes } from '@mui/system';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from 'framer-motion';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const OrdersTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [damages, setDamages] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [searchDate, setSearchDate] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [dateRange, setDateRange] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);



    const fetchData = async () => {
        setLoading(true);
        try {
            const ordersResponse = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Orders = ordersResponse.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setOrders(Orders);
                setLoading(false);
            }

            const damagesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Damages = damagesResponse.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setDamages(Damages);
                setLoading(false);
            }

            const expensesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Expenses = expensesResponse.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setExpenses(Expenses);
                setLoading(false);
            }

            const stocksResponse = await axios.get('https://admin.bizstor.lk/public/api/get-stock-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Stocks = stocksResponse.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setStocks(Stocks);
                setLoading(false);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchDate(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    const filterByDate = (data, dateKey) => {
        if (!searchDate) return data;
        return data.filter(item => new Date(item[dateKey]).toDateString() === new Date(searchDate).toDateString());
    };

    const filterByMonth = (data, dateKey) => {
        if (!selectedMonth) return data;
        return data.filter(item => {
            const date = new Date(item[dateKey]);
            return date.getMonth() + 1 === parseInt(selectedMonth);
        });
    };

    const filteredOrders = filterByDate(filterByMonth(filterByDateRange(orders, 'invoicedate', dateRange), 'invoicedate'), 'invoicedate');
    const filteredDamages = filterByDate(filterByMonth(filterByDateRange(damages, 'invoicedate', dateRange), 'invoicedate'), 'invoicedate');
    const filteredExpenses = filterByDate(filterByMonth(filterByDateRange(expenses, 'date', dateRange), 'date'), 'date');
    const filteredStocks = filterByDate(filterByMonth(filterByDateRange(stocks, 'date', dateRange), 'date'), 'date');

    const calculateItemTotal = (item, order) => {
        const itemTax = (Number(item.price) * (item.tax / 100)) || 0;
        const itemTotalBeforeDiscount = Number(item.price) + itemTax;
        const discountPercentage = parseFloat(order.discount) || 0;
        const discountAmount = (itemTotalBeforeDiscount * discountPercentage) / 100;
        return itemTotalBeforeDiscount - discountAmount;
    };

    const groupedDamages = filteredDamages.reduce((acc, damage) => {
        damage.items.forEach(item => {
            const key = `${item.product}-${damage.type}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    rate: item.rate,
                    quantity: 0,
                    totalPrice: 0,
                    type: damage.type
                };
            }
            acc[key].quantity += Number(item.quantity);
            acc[key].totalPrice += Number(item.price);
        });
        return acc;
    }, {});


    const groupedExpenses = filteredExpenses.reduce((acc, expense) => {
        expense.items.forEach(item => {
            const { category, amount } = item;
            if (!acc[category]) {
                acc[category] = {
                    category,
                    totalAmount: 0,
                };
            }
            acc[category].totalAmount += Number(amount);
        });
        return acc;
    }, {});

    const groupedStocks = filteredStocks.reduce((acc, stock) => {
        stock.items.forEach(item => {
            const key = `${item.product}-${item.status}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    quantity: 0,
                    status: item.status
                };
            }
            acc[key].quantity += Number(item.quantity);
        });
        return acc;
    }, {});

    const groupedOrders = filteredOrders.reduce((acc, order) => {
        order.items.forEach(item => {
            const key = `${item.product}-${item.rate}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    rate: item.rate,
                    quantity: 0,
                    totalPrice: 0,
                    totalDiscount: 0,
                    totalTax: 0,
                    total: 0,
                };
            }
            acc[key].quantity += Number(item.quantity);
            acc[key].totalPrice += item.rate * Number(item.quantity);
            acc[key].totalDiscount += (item.rate * Number(item.quantity) * order.discount) / 100;
            acc[key].totalTax += (item.rate * Number(item.quantity) * item.tax) / 100;

            // Calculate base total
            acc[key].total = acc[key].totalPrice - acc[key].totalDiscount + acc[key].totalTax;
        });
        return acc;
    }, {});

    Object.values(groupedOrders).forEach(order => {
        const damageKey = `${order.product}-${order.rate}`;
        const damage = Object.values(groupedDamages).find(
            d => `${d.product}-${d.rate}` === damageKey && d.type === "money back"
        );

        // If damage exists and it's a "money back" type, adjust total
        const damageTotalPrice = damage ? damage.totalPrice : 0;
        order.total -= damageTotalPrice;
    });

    const { totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal } = Object.values(groupedOrders).reduce(
        (totals, { totalPrice, totalDiscount, totalTax, total }) => {
            totals.totalPriceAmount += totalPrice;
            totals.totalDiscountAmount += totalDiscount;
            totals.totalTaxAmount += totalTax;
            totals.grandTotal += total; // Use adjusted total here
            return totals;
        },
        {
            totalPriceAmount: 0,
            totalDiscountAmount: 0,
            totalTaxAmount: 0,
            grandTotal: 0,
        }
    );

    const { damageTotalQuantity, damageTotalPrice } = Object.values(groupedDamages).reduce(
        (totals, { quantity, totalPrice, type }) => {
            if (type === "money back") {
                totals.damageTotalPrice += totalPrice;
            } else if (type === "replace") {
                totals.damageTotalQuantity += Number(quantity);
            }
            return totals;
        },
        {
            damageTotalQuantity: 0,
            damageTotalPrice: 0,
        }
    );

    const expensesTotalAmount = Object.values(groupedExpenses).reduce(
        (total, { totalAmount }) => total + totalAmount,
        0
    );

    const { stockTotalFilled, stockTotalEmpty } = Object.values(groupedStocks).reduce(
        (totals, { quantity, status }) => {
            if (status === "Filled") {
                totals.stockTotalFilled += Number(quantity);
            } else if (status === "Empty") {
                totals.stockTotalEmpty += Number(quantity);
            }
            return totals;
        },
        {
            stockTotalFilled: 0,
            stockTotalEmpty: 0,
        }
    );

    // Define the month names array
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Handle the print functionality
    const handlePrint = () => {
        // Get the selected month name
        const selectedMonthName = selectedMonth ? monthNames[parseInt(selectedMonth) - 1] : 'All Months';

        // Call printContent with the selected month name
        printContent(
            groupedOrders, totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal,
            groupedDamages, damageTotalQuantity, damageTotalPrice,
            groupedExpenses, expensesTotalAmount,
            groupedStocks, stockTotalFilled, stockTotalEmpty,
            selectedMonthName, user // Add this parameter
        );
    };


    const profit = grandTotal - expensesTotalAmount - damageTotalPrice;

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Sales By Product
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Report - Sales By Product
                    </Typography>
                </Box>
            </AnimatedTypography>
            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Sales By Product
            </AnimatedTypography>*/}
            <Box
           sx={{     display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                      marginTop: '10px',
                            },
      }}
            >
                <Typography variant="h4" >

                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>SALES BY PRODUCT</h2>
                    </Box>   
                   
                <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            >
                <Box
                    sx={{
                        display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                    }}
                >
                    {/**    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearchChange}
                        value={searchDate}
                        size="small"
                        type="date"
                        sx={{
                            width: '200px',
                            height: '40px'
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />*/}

                    <Select
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        displayEmpty
                        size="small"
                        sx={{
                            width: '250px',
                            height: '40px',
                            mt: 1,
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                        }}                    >
                        {monthOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        displayEmpty
                        size="small"
                        sx={{
                            width: '250px',
                            height: '40px',
                            mt: 1,
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                            
                        }}
                    >   <MenuItem value=""><em>All</em></MenuItem>
                        <MenuItem value="last7Days">Last 7 Days</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        <MenuItem value="last3Months">Last 3 Months</MenuItem>
                        <MenuItem value="lastYear">Last Year</MenuItem>
                    </Select>
                    </Box>
                    </motion.div>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                        }}
                    >
                        <Box>
                          <IconButton 
                    onClick={toggleSearch}
                    sx={{
                        display:{sx:'flex',md:'none'},
                        '@media (max-width: 900px)': {
                                display:'none'
                            },
                            '@media (max-width: 600px)': {
                                display:'flex'
                            }
                    }}
                    >
                    <SearchIcon />
                </IconButton>  
                        </Box>
                        <Box
                        sx={{
                            display:{sx:'none',md:'flex'},
                            gap:1
                        }}
                        >
                            <Box>
                            <Select
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        displayEmpty
                        size="small"
                        sx={{
                            width: '190px',
                            height: '40px',
                            mt: 1,
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                            '@media (max-width: 600px)': {
                                display:'none'
                            }
                        }}                    >
                        {monthOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                            </Box>
                            <Box>
                              <Select
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        displayEmpty
                        size="small"
                        sx={{
                            width: '190px',
                            height: '40px',
                            mt: 1,
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                            '@media (max-width: 600px)': {
                                display:'none'
                            }
                            
                        }}
                    >   <MenuItem value=""><em>All</em></MenuItem>
                        <MenuItem value="last7Days">Last 7 Days</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        <MenuItem value="last3Months">Last 3 Months</MenuItem>
                        <MenuItem value="lastYear">Last Year</MenuItem>
                    </Select>
  
                            </Box>
                        </Box>
                    <Box
                    sx={{
                        mt:{xs:'none',md:'9px'}
                    }}
                    >
                        <IconButton onClick={handlePrint}>
                            <PrintIcon />
                        </IconButton>
                    </Box>
                        
                    </Box>
                </Box>
                {/**  <Typography variant="h4" component="h1" gutterBottom
                    sx={{
                        ml: 3
                    }}
                >
                    Sales By Product
                </Typography>*/}

                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{}}>
                                <TableCell><Typography variant="subtitle2" fontWeight="bold">Product</Typography></TableCell>
                                <TableCell><Typography variant="subtitle2" fontWeight="bold">Qty</Typography></TableCell>
                                {/**    <TableCell><Typography variant="subtitle2" fontWeight="bold">%</Typography></TableCell> */}
                                <TableCell sx={{ textAlign: 'right' }}><Typography variant="subtitle2" fontWeight="bold">TOTAL</Typography></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(groupedOrders).map(({ product, quantity, total }, index) => (
                                <TableRow key={`grouped-order-${index}`} sx={{ '&:nth-of-type(odd)': {} }}>
                                    <TableCell sx={{ color: '#636363', fontSize: '14px' }}>{product}</TableCell>
                                    <TableCell sx={{ color: '#636363', fontSize: '14px' }}>{quantity}</TableCell>
                                    {/**   <TableCell></TableCell> */}
                                    <TableCell sx={{ textAlign: 'right', color: '#636363', fontSize: '14px' }}>{total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle2" fontWeight="bold"></Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle2" fontWeight="bold"></Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }} colSpan={1}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}><b>Total Sales</b></Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }} colSpan={1}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}><b>Lkr.{grandTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/**    <Box display="flex" justifyContent="flex-end" fontWeight="bold"
                sx={{
                    mt: 3,
                    
                }}>
                    <p>Profit: Lkr.{profit.toFixed(2)}</p>
                </Box>*/}
            </Box>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}
            <ScrollToTopButton />
        </Box>
    );
};

export default OrdersTable;
