import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profit = ({ onProfitChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [damages, setDamages] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalCostOfSales, setTotalCostOfSales] = useState(0);
    const [profit, setProfit] = useState(0);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const ordersResponse = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            const damagesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            const expensesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
            const stocksResponse = await axios.get('https://admin.bizstor.lk/public/api/get-stock-data');
            const productResponse = await axios.get('https://admin.bizstor.lk/public/api/get-product-data');

            if (user.id) {
                const Orders = ordersResponse.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setOrders(Orders);

                const Damages = damagesResponse.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setDamages(Damages);

                const Expenses = expensesResponse.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setExpenses(Expenses);

                const Stocks = stocksResponse.data.filter(stock =>
                    stock.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setStocks(Stocks);

                const Product = productResponse.data.products.filter(
                    (product) => product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setProducts(Product);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const filterByCurrentMonth = (data, dateKey) => {
        const currentMonth = new Date().getMonth();
        return data.filter(item => {
            const date = new Date(item[dateKey]);
            return date.getMonth() === currentMonth;
        });
    };

    const filteredOrders = filterByCurrentMonth(orders, 'invoicedate');
    const filteredDamages = filterByCurrentMonth(damages, 'invoicedate');
    const filteredExpenses = filterByCurrentMonth(expenses, 'date');
    const filteredStocks = filterByCurrentMonth(stocks, 'date');

    const groupedDamages = filteredDamages.reduce((acc, damage) => {
        damage.items.forEach(item => {
            const key = `${item.product}-${damage.type}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    rate: item.rate,
                    quantity: 0,
                    totalPrice: 0,
                    type: damage.type
                };
            }
            acc[key].quantity += item.quantity;
            acc[key].totalPrice += item.price;
        });
        return acc;
    }, {});

    const groupedExpenses = filteredExpenses.reduce((acc, expense) => {
        expense.items.forEach(item => {
            const { category, amount } = item;
            if (!acc[category]) {
                acc[category] = {
                    category,
                    totalAmount: 0,
                };
            }
            acc[category].totalAmount += amount;
        });
        return acc;
    }, {});

    const groupedStocks = filteredStocks.reduce((acc, stock) => {
        stock.items.forEach(item => {
            const key = `${item.product}-${item.status}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    quantity: 0,
                    status: item.status
                };
            }
            acc[key].quantity += item.quantity;
        });
        return acc;
    }, {});

    const groupedOrders = filteredOrders.reduce((acc, order) => {
        order.items.forEach(item => {
            const key = `${item.product}-${item.rate}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    rate: item.rate,
                    quantity: 0,
                    totalPrice: 0,
                    totalDiscount: 0,
                    totalTax: 0,
                    total: 0
                };
            }
            acc[key].quantity += item.quantity;
            acc[key].totalPrice += item.rate * item.quantity;
            acc[key].totalDiscount += (item.rate * item.quantity * order.discount / 100);
            acc[key].totalTax += (item.rate * item.quantity * item.tax / 100);

            const total = acc[key].totalPrice - acc[key].totalDiscount + acc[key].totalTax;
            acc[key].total = total;
        });
        return acc;
    }, {});

    const { totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal } = Object.values(groupedOrders).reduce(
        (totals, { totalPrice, totalDiscount, totalTax }) => {
            totals.totalPriceAmount += totalPrice;
            totals.totalDiscountAmount += totalDiscount;
            totals.totalTaxAmount += totalTax;
            totals.grandTotal += totalPrice + totalTax - totalDiscount;
            return totals;
        },
        {
            totalPriceAmount: 0,
            totalDiscountAmount: 0,
            totalTaxAmount: 0,
            grandTotal: 0,
        }
    );

    const { damageTotalQuantity, damageTotalPrice } = Object.values(groupedDamages).reduce(
        (totals, { quantity, totalPrice, type }) => {
            if (type === "money back") {
                totals.damageTotalPrice += totalPrice;
            } else if (type === "replace") {
                totals.damageTotalQuantity += quantity;
            }
            return totals;
        },
        {
            damageTotalQuantity: 0,
            damageTotalPrice: 0,
        }
    );

    const expensesTotalAmount = Object.values(groupedExpenses).reduce(
        (total, { totalAmount }) => total + totalAmount,
        0
    );

    const { stockTotalFilled, stockTotalEmpty } = Object.values(groupedStocks).reduce(
        (totals, { quantity, status }) => {
            if (status === "item") {
                totals.stockTotalFilled += quantity;
            } else if (status === "Empty") {
                totals.stockTotalEmpty += quantity;
            }
            return totals;
        },
        {
            stockTotalFilled: 0,
            stockTotalEmpty: 0,
        }
    );

    const getBpriceForProduct = (productName) => {
        const matchingProduct = products.find((product) => product.product === productName);
        return matchingProduct ? matchingProduct.Bprice || 'N/A' : 'N/A';
    };

    const calculateTotal = (productName, quantity) => {
        const bprice = getBpriceForProduct(productName);
        return bprice * quantity;
    };

    useEffect(() => {
        let total = 0;
        Object.keys(groupedStocks).forEach((key) => {
            const stockItem = groupedStocks[key];
            if (stockItem.status === "item") {
                total += calculateTotal(stockItem.product, stockItem.quantity);
            }
        });
        setTotalCostOfSales(total);
    }, [groupedStocks, products]);

    useEffect(() => {
        const calculatedProfit = grandTotal  - expensesTotalAmount - damageTotalPrice;
        setProfit(calculatedProfit);

        if (onProfitChange) {
            onProfitChange(calculatedProfit);
        }
    }, [grandTotal, totalCostOfSales, expensesTotalAmount, damageTotalPrice, onProfitChange]);

    return null; // No rendering
};

export default Profit;
