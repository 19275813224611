export const printCustomerReport = (data) => {
    // Calculate the grand total
    const grandTotal = data.reduce((sum, customer) => sum + customer.total, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const printWindow = window.open('', '_blank');

    const reportContent = `
        <html>
            <head>
                <title>Sales By Customer</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        margin: 20px;
                        padding: 0;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 20px;
                    }
                    th, td {
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                    }
                    h4 {
                        text-align: center;
                        border-bottom:2px solid black;
                        padding-bottom: 10px;
                    }
                    .grand-total {
                    display:flex;
                    justify-conternt:space-between;
                        margin-top: 20px;
                        font-weight: bold;
                    }
                </style>
            </head>
            <body>
                <h4>Customer Report</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th style="text-align: right;">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${data.map(customer => `
                            <tr>
                                <td>${customer.fullname ? customer.fullname : 'Direct customer' }</td>
                                <td style="text-align: right;">${customer.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
                <div >
                <p style="display:flex; justify-content:space-between; font-weight:bold; border-bottom:1px solid black;border-top:1px solid black">
                <span>TOTAL</span><span> Lkr.${grandTotal}</span>
                </p>
                </div>
            </body>
        </html>
    `;

    printWindow.document.open();
    printWindow.document.write(reportContent);
    printWindow.document.close();
    printWindow.print();
};
