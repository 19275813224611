import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const ProfitAndLoss = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [orders, setOrders] = useState([]);
    const [damages, setDamages] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true); // Set loading to true when starting to fetch data
        try {
            const ordersResponse = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            const filteredOrders = ordersResponse.data.filter(order =>
                order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
            );
            setOrders(filteredOrders);

            const damagesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            const filteredDamages = damagesResponse.data.filter(damage =>
                damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
            );
            setDamages(filteredDamages);

            calculateGrandTotal(filteredOrders, filteredDamages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false); // Set loading to false after data is fetched and processed
    };

    const calculateGrandTotal = (orders, damages) => {
        const groupedOrders = orders.reduce((acc, order) => {
            order.items.forEach(item => {
                const rate = item.rate || 0; // Ensure rate is a valid number
                const quantity = item.quantity || 0; // Ensure quantity is a valid number
                const discount = order.discount || 0; // Ensure discount is a valid number
                const tax = item.tax || 0; // Ensure tax is a valid number

                const key = `${item.product}-${rate}`;
                if (!acc[key]) {
                    acc[key] = { totalPrice: 0, totalDiscount: 0, totalTax: 0, total: 0 };
                }

                // Calculate total price, discount, tax, and final total
                acc[key].totalPrice += rate * quantity;
                acc[key].totalDiscount += (rate * quantity * discount) / 100;
                acc[key].totalTax += (rate * quantity * tax) / 100;
                acc[key].total = acc[key].totalPrice - acc[key].totalDiscount + acc[key].totalTax;
            });
            return acc;
        }, {});

        Object.values(groupedOrders).forEach(order => {
            const damageKey = `${order.product}-${order.rate}`;
            const damage = damages.find(d => `${d.product}-${d.rate}` === damageKey && d.type === "money back");
            if (damage) order.total -= damage.totalPrice;
        });

        const grandTotalValue = Object.values(groupedOrders).reduce(
            (totals, { total }) => totals + total, 0
        );

        setGrandTotal(grandTotalValue || 0); // Ensure the final value is not NaN
    };

    

    return (
        <>
            {loading ? (
                <>loading...</>
            ) : (
                <>Lkr.{Number(grandTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )}
        </>
    );
};

export default ProfitAndLoss;
