import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Grid,
    Toolbar,
    AppBar,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    TextField,
    DialogContent,
    FormControl,
    Select,
    Slide,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { printTable } from './Print/StockPrint';
import { styled, keyframes } from '@mui/system';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ScrollToTopButton from '../../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from 'framer-motion';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Stocktable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([{ product: '', bottleStatus: '', quantity: '' }]);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };



    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-stock-data');

            if (user.id) {

                // Filter orders based on user.id
                const nisalOrders = response.data.filter(stock =>
                    stock.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedData(nisalOrders);
                setFilteredData(nisalOrders);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const selectedRowsData = fetchedData.filter((stock) => selectedRows.includes(stock.id));

    const handlePrint = () => {
        printTable(selectedRowsData);
    };

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, stockId) => {
        const selectedIndex = selectedRows.indexOf(stockId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, stockId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (stockId) => selectedRows.indexOf(stockId) !== -1;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/public/api/delete-stock-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(stock => !selectedRows.includes(stock.id)));
                handleSnackbar('Selected inventory deleted successfully.!', 'success');
                setAlertOpen(true);
                setSelectedRows([]);
                setDeleteConfirmationOpen(false);
                fetchData();
            } else {
                handleSnackbar('Failed deleting inventory!', 'error');

            }
        } catch (error) {
            console.error('Error deleting data:', error);
            handleSnackbar('Error deleting inventory!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const calculateTotalQuantity = (items) => {
        if (!items) return 0;
        return items.reduce((total, item) => total + Number(item.quantity), 0);
    };

    const handleEditClick = (stock) => {
        setSelectedStock(stock);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setSelectedStock(null);
    };


    const handleSave = async () => {
        setLoading(true);
        try {
            await axios.put(`https://admin.bizstor.lk/public/api/update-stock-data/${selectedStock.id}`, selectedStock);
            fetchData(); // refresh the data after save
            handleCloseEditDialog();
            handleSnackbar('inventory update successfully!', 'success');

        } catch (error) {
            console.error('Error updating data:', error);
            handleSnackbar('Failed to update inventory!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleStockItemChange = (index, field, value) => {
        const updatedItems = selectedStock.items.map((item, itemIndex) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    [field]: value,
                };
            }
            return item;
        });

        setSelectedStock((prevStock) => ({
            ...prevStock,
            items: updatedItems,
        }));
    };

    const handleStockChange = (field, value) => {
        setSelectedStock((prevStock) => ({
            ...prevStock,
            [field]: value,
        }));
    };

    const handleAddRow = () => {
        // Create a new item with default values
        const newItem = {
            id: Date.now(), // Unique ID for the item
            product: '',    // Default product
            status: 'Filled', // Default status
            quantity: 0,    // Default quantity
        };

        // Update the selectedStock state
        setSelectedStock((prevState) => ({
            ...prevState,
            items: [...prevState.items, newItem],
        }));
    };

    const [selectedStock, setSelectedStock] = useState({
        branch: '',
        date: '',
        items: [], // Array to hold the stock items
    });

    const handleDeleteRow = (index) => {
        // Filter out the item to be deleted based on the index
        const updatedItems = selectedStock.items.filter((_, i) => i !== index);

        // Update the selectedStock state
        setSelectedStock((prevState) => ({
            ...prevState,
            items: updatedItems,
        }));
    };

    useEffect(() => {
        axios.get('https://admin.bizstor.lk/public/api/get-product-data')
            .then(response => {
                if (user.id) {
                    {/**console.log('User ID:', user.id);*/}
    
                    // Filter products based on user token
                    const filteredProducts = response.data.products.filter(product =>
                        product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setProducts(filteredProducts);
                } else {
                    setProducts(response.data.products); 
                }
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, [user.id]); 
    

    const productPrices = {};
    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    const [selectedMonth, setSelectedMonth] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value, selectedMonth);
    };

    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value);
        filterData(searchTerm, value);
    };

    const filterData = (term, month) => {
        const filtered = fetchedData.filter(stock => {
            const stockDate = new Date(stock.date);
            const stockMonth = stockDate.toLocaleString('default', { month: 'long' }).toLowerCase();
            return (
                (term === '' || stock.date.includes(term)) &&
                (month === '' || stockMonth === month.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    return (
        <Box>
                       <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Inventory
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Report - Inventory
                    </Typography>
                </Box>
            </AnimatedTypography>

            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Inventory
            </AnimatedTypography>*/}
            <Box
           sx={{     display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                      marginTop: '10px',
                            },
      }}
            >
                <Typography variant="h4">
                    
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections
                    }}
                >
                     <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>INVENTORY</h2>
                    </Box>   
                   
                <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            >
                    {/* Left side: Search fields */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically
'@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                        }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            type="date"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px',
                                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                             }}
                        />

                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            size="small"
                            sx={{
                                width: '250px',
                                height: '40px',
                                mt: 1,
                                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                            }}
                        >
                            <MenuItem value="">
                                <em>All Months</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                        </Select>
                    </Box></motion.div>

                    {/* Right side: Icons */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                            mt: { xs: 2, sm: 0 }, // Add margin-top on small screens
                            justifyContent: { xs: 'center', sm: 'flex-end' }, // Center on small screens
                            width: { xs: '100%', sm: 'auto' }, // Full width on small screens
                        }}
                    >
                        <IconButton onClick={toggleSearch}>
                    <SearchIcon />
                </IconButton>
                        <IconButton
                            aria-label="print"
                            onClick={handlePrint}
                            disabled={selectedRows.length === 0}
                        >
                            <PrintIcon />
                        </IconButton>

                        <IconButton
                            aria-label="delete"
                            onClick={handleMenuOpen}
                            disabled={selectedRows.length === 0}
                        >
                            <DeleteIcon
                                sx={{
                                    '&:hover': {
                                        color: 'red',
                                    },
                                }}
                            />
                        </IconButton>

                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleOpenDeleteConfirmation}>
                                Delete Selected Inventory
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>

                <Dialog
                    open={deleteConfirmationOpen}
                    onClose={handleCloseDeleteConfirmation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected inventory?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteConfirmation} color="primary">
                            No
                        </Button>
                        <Button onClick={handleDelete} 
                        sx={{
                            backgroundColor:'red',
                            color:'white',
                            '&:hover': {
                                color: 'white',
                                backgroundColor:'red',
                                },
                        }}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <TableContainer  style={{ marginTop: '20px',  }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                        checked={selectedRows.length === fetchedData.length}
                                        onChange={() => {
                                            if (selectedRows.length === fetchedData.length) {
                                                setSelectedRows([]);
                                            } else {
                                                setSelectedRows(fetchedData.map((stock) => stock.id));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell><b>ID</b></TableCell>
                                {/**    <TableCell><b>Branch</b></TableCell>*/}
                                <TableCell><b>Date</b></TableCell>
                                <TableCell><b>Total Qty</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.sort((a, b) => new Date(b.id) - new Date(a.id)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((stock, index) => {
                                const isItemSelected = isSelected(stock.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const totalQuantity = calculateTotalQuantity(stock.items);

                                return (
                                    <React.Fragment key={stock.id}>
                                        <TableRow
                                            hover
                                            onClick={() => handleRowClick(index)}
                                            role="checkbox"
                                            aria-checked={isSelected(stock.id)}
                                            tabIndex={-1}
                                            selected={isSelected(stock.id)}
                                            style={{ backgroundColor: openRow === index ? '#f0f0f0' : 'inherit' }}
                                        >
                                            <TableCell padding="checkbox" >
                                                <Checkbox
                                                    checked={isSelected(stock.id)}
                                                    onChange={(event) => handleRowSelect(event, stock.id)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{  fontSize: '14px' }}>{stock.id}</TableCell>
                                            {/**    <TableCell>{stock.branch}</TableCell>*/}
                                            <TableCell sx={{  fontSize: '14px' }}>{stock.date}</TableCell>
                                            <TableCell sx={{  fontSize: '14px' }}>{totalQuantity}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEditClick(stock)}>
                                                    <EditNoteIcon
                                                        sx={{
                                                            '&:hover': {
                                                                color: 'black',
                                                            },
                                                        }}
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                    <Box margin={1} padding={2} bgcolor="#f9f9f9" borderRadius={4} >
                                                        <Typography variant="h6" gutterBottom component="div" color="primary">
                                                            Stock Details
                                                        </Typography>
                                                        <Table size="small" aria-label="purchases">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Product</TableCell>
                                                                    <TableCell>Status</TableCell>
                                                                    <TableCell>Qty</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {stock.items && stock.items.map((item) => (
                                                                    <TableRow key={item.id}>
                                                                        <TableCell sx={{ color: '#636363', fontSize: '14px' }}>{item.product}</TableCell>
                                                                        <TableCell sx={{ color: '#636363', fontSize: '14px' }}>{item.status}</TableCell>
                                                                        <TableCell sx={{ color: '#636363', fontSize: '14px' }}>{item.quantity}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>

                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

<Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={2}
                    >
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={fetchedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
</Box>

                <Dialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseEditDialog} aria-label="close">
                                <CloseIcon sx={{ color: 'white' }} />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                Edit stock
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {selectedStock && (
                            <form>
                                <Grid container spacing={2}>
                                    {/**  <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            label="Branch"
                                            fullWidth
                                            size="small"
                                            value={selectedStock.branch}
                                            onChange={(e) => handleStockChange('branch', e.target.value)}
                                        />
                                    </Grid>*/}
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            label="Date"
                                            type="date"
                                            fullWidth
                                            size="small"
                                            value={selectedStock.date}
                                            onChange={(e) => handleStockChange('date', e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px', marginBottom: '10px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddRow}
                                        style={{ backgroundColor: 'black', color: 'white' }}
                                        sx={{
                                            height: '30px',
                                        }}
                                    >
                                        <PlaylistAddIcon />
                                    </Button>
                                </Box>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedStock.items && selectedStock.items.map((item, index) => (
                                            <TableRow key={item.id} sx={{ height: '40px' }}>
                                                <TableCell sx={{ padding: '8px' }}>
                                                    <FormControl fullWidth variant="outlined" size="small">
                                                        <Select
                                                            value={item.product}
                                                            onChange={(e) => handleStockItemChange(index, 'product', e.target.value)}
                                                        >
                                                            {products.map((product) => (
                                                                <MenuItem key={product.id} value={product.product}>
                                                                    {product.product}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell sx={{ padding: '8px' }}>
                                                    <Select
                                                        value={item.status}
                                                        onChange={(e) => handleStockItemChange(index, 'status', e.target.value)}
                                                        size="small"
                                                    >
                                                        <MenuItem value="item">Item</MenuItem>
                                                        <MenuItem value="Filled">Filled</MenuItem>
                                                        <MenuItem value="Empty">Empty</MenuItem>
                                                    </Select>
                                                </TableCell>
                                                <TableCell sx={{ padding: '8px' }}>
                                                    <TextField
                                                        value={item.quantity}
                                                        onChange={(e) => handleStockItemChange(index, 'quantity', e.target.value)}
                                                        type="number"
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '8px' }}>
                                                    <IconButton
                                                        onClick={() => handleDeleteRow(index)}
                                                        aria-label="delete"
                                                        color="inherit"
                                                        size="small"
                                                    >
                                                        <DeleteIcon
                                                            sx={{
                                                                '&:hover': {
                                                                    color: 'red',
                                                                },
                                                            }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </form>
                        )}
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <ScrollToTopButton/>
        </Box>
    );
};

export default Stocktable;
