import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useMediaQuery from '@mui/material/useMediaQuery';

const Chart3 = ({ onTotalAmountChange }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [fetchedData, setFetchedData] = useState([]);
  const [fetchedExpenses, setFetchedExpenses] = useState([]);
  const [fetchedDamageData, setFetchedDamageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dayByDayData, setDayByDayData] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Checks if the screen width is 600px or smaller

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return;

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
          const nisalOrders = response.data.filter(
            (order) => order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );
          setFetchedData(nisalOrders);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchExpenses = async () => {
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
          const nisalOrders = response.data.filter(
            (expense) => expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );
          setFetchedExpenses(nisalOrders);
        } catch (error) {
          console.error('Error fetching expenses data:', error);
        }
      };

      const fetchDamageData = async () => {
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
          const nisalOrders = response.data.filter(
            (damage) => damage.usertoken.toLowerCase() === user.id.toString().toLowerCase() && damage.type === 'money back'
          );
          setFetchedDamageData(nisalOrders);
        } catch (error) {
          console.error('Error fetching damage data:', error);
        }
      };

      fetchData();
      fetchExpenses();
      fetchDamageData();
    }
  }, [user.id]);

  useEffect(() => {
    if (!fetchedData.length && !fetchedExpenses.length && !fetchedDamageData.length) return;

    const now = new Date();
    const currentDay = now.getDay();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - (currentDay === 0 ? 6 : currentDay - 1));
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const salesTotals = Array(7).fill(0);
    const expenseTotals = Array(7).fill(0);
    const damageTotals = Array(7).fill(0);

    const getDayIndex = (date) => {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    };

    fetchedData.forEach((order) => {
      const createdAt = new Date(order.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
        let orderTotal = 0;

        order.items.forEach((item) => {
          const itemPrice = Number(item.price);
          const itemTax = item.tax || 0;
          const itemTotal = itemPrice + itemPrice * (itemTax / 100);
          orderTotal += itemTotal;
        });

        const discountPercentage = order.discount || 0;
        const discountAmount = (orderTotal * discountPercentage) / 100;
        orderTotal -= discountAmount;

        salesTotals[dayIndex] += orderTotal;
      }
    });

    fetchedExpenses.forEach((expense) => {
      const createdAt = new Date(expense.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);

        expense.items.forEach((item) => {
          expenseTotals[dayIndex] += Number(item.amount);
        });
      }
    });

    fetchedDamageData.forEach((damage) => {
      const createdAt = new Date(damage.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);

        damage.items.forEach((item) => {
          damageTotals[dayIndex] += Number(item.price);
        });
      }
    });

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const data = daysOfWeek.map((day, index) => ({
      name: day,
      sales: salesTotals[index],
      expenses: expenseTotals[index],
      return: damageTotals[index],
    }));

    setDayByDayData(data);

    if (onTotalAmountChange) {
      onTotalAmountChange(data);
    }
  }, [fetchedData, fetchedExpenses, fetchedDamageData, onTotalAmountChange]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        boxShadow: 1,
        padding: 'auto',
        borderRadius: '5px',
        bgcolor: 'background.paper',
      }}
    >
      {loading ? (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'white',
            zIndex: 9999,
          }}
        >
          <l-trio size="50" speed="1.3" color="red"></l-trio>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" gutterBottom
          sx={{
            color:'#6d7081',
            textAlign:'center'
          }}
          >
            Sales Expenses And Returns
          </Typography>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart 
              data={dayByDayData} 
              layout={isSmallScreen ? 'vertical' : 'horizontal'} // Switch layout based on screen size
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={isSmallScreen ? "sales" : "name"} type={isSmallScreen ? "number" : "category"} />
              <YAxis type={isSmallScreen ? "category" : "number"} dataKey={isSmallScreen ? "name" : null} />
              <Tooltip />
              <Legend />
              <Bar dataKey="sales" fill="#8884d8" />
              <Bar dataKey="expenses" fill="red" />
              <Bar dataKey="return" fill="#FFB300" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
};

export default Chart3;
