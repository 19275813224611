import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Avatar,
    IconButton,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Slide,
    Alert,
    Snackbar,
    AppBar,
    Toolbar,
    Grid,
    TablePagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import ScrollToTopButton from '../../Button/ScrollToTopButton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SupplierTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [suppliers, setSuppliers] = useState([]);
    const [currentSupplier, setCurrentSupplier] = useState({});
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState(''); 
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedSupplierId, setSelectedSupplierId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [supplier, setSupplier] = useState([]);

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchSuppliers();
        }
    }, [user.id]);


    const fetchSuppliers = () => {
        setLoading(true);

        axios.get('https://admin.bizstor.lk/public/api/suppliers')
            .then(response => {
                if (user.id) {
                    const filterCustomer = response.data.filter(customer =>
                        customer.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setSuppliers(filterCustomer);        // Ensure setSuppliers is defined
                    setFilteredData(filterCustomer);     // Ensure setFilteredData is defined
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error.message || error);
            })
            .finally(() => {
                setLoading(false);     // Ensure setLoading is defined
            });
    };


    useEffect(() => {
        fetchSuppliers();
    }, []); // Fetch customers on component mount

    const handleDelete = (supplierId) => {
        setSelectedSupplierId(supplierId);
        setOpenConfirmation(true);
    };

    const confirmDelete = () => {
        setLoading(true);
        axios.delete(`https://admin.bizstor.lk/public/api/suppliers/${selectedSupplierId}`)
            .then(response => {
                setSuppliers(suppliers.filter(supplier => supplier.id !== selectedSupplierId));
                setLoading(false);
                setOpenConfirmation(false);
                handleSnackbar('Supplier deleted successfully!', 'success');
                setOpenAlert(true);
                fetchSuppliers();
            })
            .catch(error => {
                console.error('Error deleting product:', error);
                setLoading(false);
                setOpenConfirmation(false);
                handleSnackbar('Failed to deleted supplier!', 'error');
                setOpenAlert(true);
            });
    };



    const handleUpdateClick = (supplier) => {
        setCurrentSupplier(supplier);
        setOpenUpdateDialog(true);
    };

    const handleUpdateChange = (e) => {
        setCurrentSupplier({
            ...currentSupplier,
            [e.target.name]: e.target.value
        });
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleUpdateSubmit = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('supplier_name', currentSupplier.supplier_name);
        formData.append('contact_person', currentSupplier.contact_person);
        formData.append('email', currentSupplier.email);
        formData.append('phone', currentSupplier.phone);
        formData.append('address', currentSupplier.address);

        if (image) {
            formData.append('image', image);
        }

        axios.post(`https://admin.bizstor.lk/public/api/update-suppliers-data/${currentSupplier.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                setSuppliers(suppliers.map(supplier =>
                    supplier.id === currentSupplier.id ? response.data.supplier : supplier
                ));
                setLoading(false);
                setOpenUpdateDialog(false);
                handleSnackbar('supplier updated successfully!', 'success');
                fetchSuppliers();
            })
            .catch(error => {
                console.error('Error updating supplier:', error);
                setLoading(false);
                handleSnackbar('Failed to updated supplier!', 'error');
                setOpenAlert(true);
            });
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value);
    };

    const filterData = (term) => {
        const filtered = suppliers.filter(supplier =>
            supplier.id.toString().includes(term) ||
            supplier.supplier_name.toLowerCase().includes(term) ||
            supplier.phone.includes(term) ||
            supplier.email.includes(term)
        );
        setFilteredData(filtered);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                maxWidth: 'auto',
                margin: 'auto',
                boxShadow: 1,
                padding: '10px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative'
            }}
        >
            <Dialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                aria-labelledby="delete-dialog-title"
                fullWidth
            >
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete this supplier?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary" autoFocus>No</Button>
                    <Button onClick={confirmDelete} 
                    sx={{
                        backgroundColor:'red',
                        color:'white',
                        '&:hover': {
                            color: 'white',
                            backgroundColor:'red',
                            },
                    }}
                    >
                    Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Typography variant="h5">
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: '250px',
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                         }}
                    />

                </Box>
            </Box>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Supplier Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((supplier) => (
                            <TableRow key={supplier.id}>
                                <TableCell>
                                    {supplier.image ? (
                                        <Avatar
                                            src={`https://admin.bizstor.lk/storage/app/public/${supplier.image}`}
                                            alt={supplier.supplier_name}
                                            sx={{ width: 60, height: 60, borderRadius: '50%' }}
                                        />
                                    ) : (
                                        <Avatar
                                            sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: 'grey' }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>{supplier.supplier_name}</TableCell>
                                <TableCell>{supplier.contact_person}</TableCell>
                                <TableCell>{supplier.email}</TableCell>
                                <TableCell>{supplier.phone}</TableCell>
                                <TableCell>{supplier.address}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleUpdateClick(supplier)} aria-label="edit"
                                        sx={{
                                            '&:hover': {
                                                color: 'blue',
                                            },
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(supplier.id)} aria-label="delete"
                                        sx={{
                                            '&:hover': {
                                                color: 'red',
                                            },
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={2}
                    ><TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={supplier.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /></Box>
            

            <Dialog
                open={openUpdateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                TransitionComponent={Transition}
                aria-labelledby="update-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpenUpdateDialog(false)} aria-label="close">
                            <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Edit Supplier
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                name="supplier_name"
                                label="Supplier Name"
                                type="text"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={currentSupplier.supplier_name}
                                onChange={handleUpdateChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                name="contact_person"
                                label="Contact Person"
                                type="text"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={currentSupplier.contact_person}
                                onChange={handleUpdateChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                name="email"
                                label="Email"
                                type="text"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={currentSupplier.email}
                                onChange={handleUpdateChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                name="phone"
                                label="Phone"
                                type="text"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={currentSupplier.phone}
                                onChange={handleUpdateChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="address"
                                label="Address"
                                type="text"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={currentSupplier.address}
                                onChange={handleUpdateChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept="image/*"
                                type="file"
                                onChange={handleImageChange}
                                style={{ marginTop: '20px' }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleUpdateSubmit} color="primary">Update</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{
                        width: '100%',

                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <ScrollToTopButton />
        </Box>
    );
};

export default SupplierTable;
