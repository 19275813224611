import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

const Chart2 = ({ onTotalAmountChange, onTotalExpensesChange }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [fetchedData, setFetchedData] = useState([]);
  const [fetchedExpenses, setFetchedExpenses] = useState([]);
  const [fetchedDamageData, setFetchedDamageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [chartType, setChartType] = useState('line');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return; // Exit if no token

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);

  // Determine the current year and the previous 3 years
  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];

  useEffect(() => {
    if (user.id) {

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
        if (user.id) {

          // Filter orders based on user.id
          const nisalOrders = response.data.filter(order =>
            order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );

          setFetchedData(nisalOrders);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchExpenses = async () => {
      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
        if (user.id) {

          // Filter expenses based on user.id
          const nisalOrders = response.data.filter(expense =>
            expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );

          setFetchedExpenses(nisalOrders);
        }
      } catch (error) {
        console.error('Error fetching expenses data:', error);
      }
    };

    const fetchDamageData = async () => {
      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
        if (user.id) {

          // Filter damages based on user.id
          const nisalOrders = response.data.filter(damage =>
            damage.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
            damage.type === 'money back'
          );

          setFetchedDamageData(nisalOrders);
        }
      } catch (error) {
        console.error('Error fetching damage data:', error);
      }
    };

    fetchData();
    fetchExpenses();
    fetchDamageData();
  }
  }, [user.id]);

  useEffect(() => {
    if (!fetchedData.length && !fetchedExpenses.length && !fetchedDamageData.length) return;

    // Calculate totals for each month
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const totals = Array(12).fill(0); // 12 months in a year
    const expenseTotals = Array(12).fill(0); // 12 months in a year for expenses
    const damageTotals = Array(12).fill(0);

    fetchedData.forEach(order => {
      const createdAt = new Date(order.created_at);
      const year = createdAt.getFullYear();
      if (year === selectedYear) {
        const month = createdAt.getMonth(); // Get the month (0-11)
        order.items.forEach(item => {
          totals[month] += Number(item.price);
        });
      }
    });

    fetchedExpenses.forEach(expense => {
      const createdAt = new Date(expense.created_at);
      const year = createdAt.getFullYear();
      if (year === selectedYear) {
        const month = createdAt.getMonth(); // Get the month (0-11)
        expense.items.forEach(item => {
          expenseTotals[month] += Number(item.amount);
        });
      }
    });

    fetchedDamageData.forEach(damage => {
      const createdAt = new Date(damage.created_at);
      const year = createdAt.getFullYear();
      if (year === selectedYear) {
        const month = createdAt.getMonth(); // Get the month (0-11)
        damage.items.forEach(item => {
          damageTotals[month] += Number(item.price);
        });
      }
    });
    // Format data for charts
    const data = monthNames.map((month, index) => ({
      name: month,
      sellAmount: totals[index],
      expenseAmount: expenseTotals[index],
      returnAmount: damageTotals[index],

    }));

    setMonthlyData(data);

    if (onTotalAmountChange) {
      onTotalAmountChange(data); // You can pass the whole array of monthly data if needed
    }

    let totalExpenseAmount = expenseTotals.reduce((sum, val) => sum + val, 0);
    setTotalExpenses(totalExpenseAmount);
    if (onTotalExpensesChange) {
      onTotalExpensesChange(totalExpenseAmount);
    }
  }, [fetchedData, fetchedExpenses, selectedYear,fetchedDamageData, onTotalAmountChange, onTotalExpensesChange]);

  const totalReturnAmount = monthlyData.reduce((sum, entry) => sum + entry.returnAmount, 0);
  const totalExpenseAmount = monthlyData.reduce((sum, entry) => sum + entry.expenseAmount, 0);
  const totalOrderAmount = monthlyData.reduce((sum, entry) => sum + entry.sellAmount, 0);

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // Define dark colors for each month
  const colors = [
    '#1A237E', // January - Dark Blue
    '#283593', // February - Darker Blue
    '#303F9F', // March - Deep Blue
    '#3F51B5', // April - Indigo
    '#5C6BC0', // May - Blue Grey
    '#9E9D24', // June - Dark Yellow
    '#FBC02D', // July - Dark Amber
    '#F57F17', // August - Dark Orange
    '#E64A19', // September - Dark Red
    '#D32F2F', // October - Dark Crimson
    '#C2185B', // November - Dark Pink
    '#8E24AA'  // December - Dark Purple
  ];

  return (
    <Box
      sx={{
        display: 'flex',
          flexDirection: 'column',
          width: '95%',
          maxWidth: 'auto',
          margin: 'auto',
          boxShadow: 1,
          padding: '10px',
          borderRadius: '5px',
          bgcolor: 'background.paper',
          marginTop: '30px',
          position: 'relative'
      }}
    >
      {loading ? (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          zIndex: 9999
        }}>
          <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
        </Box>
      ) : (
        <div>
          <Box>
          <Typography variant="h6" gutterBottom sx={{
            mb: 3
          }}>
            Sales, Returns and Expenses for {selectedYear}
          </Typography>

          <Box
              sx={{
                position: 'relative', // Changed to relative for better positioning in a responsive layout
                display: 'flex',
                flexWrap: 'wrap', // Ensures items wrap on smaller screens
                justifyContent: 'flex-end', // Aligns content to the right
                gap: '10px', // Adds space between items
                mt: '5px',
                mb: '10px',
                top: '5px',
              }}
            >
  <FormControl
    variant="outlined" sx={{ width: { xs: '100%', sm: '150px' } }}
  >
    <InputLabel id="year-select-label">Select Year</InputLabel>
    <Select
      labelId="year-select-label"
      id="year-select"
      value={selectedYear}
      onChange={handleYearChange}
      label="Select Year"
      size="small"
      sx={{ mr: { xs: 0, sm: 2 } }} 
    >
      {years.map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  <FormControl
    variant="outlined" sx={{ width: { xs: '100%', sm: '150px' } }}
    >
    <InputLabel id="chart-type-label">Select Chart Type</InputLabel>
    <Select
      labelId="chart-type-label"
      id="chart-type-select"
      value={chartType}
      onChange={handleChartTypeChange}
      label="Select Chart Type"
      size="small"
      sx={{ mr: { xs: 0, sm: 2 } }} 

    >
      <MenuItem value="line">Line Chart</MenuItem>
      <MenuItem value="bar">Bar Chart</MenuItem>
      <MenuItem value="pie">Pie Chart</MenuItem>
    </Select>
  </FormControl>
</Box>


          </Box>
          {/* Conditional Rendering based on chartType */}
          {chartType === 'line' && (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={monthlyData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="sellAmount" stroke="#8884d8" strokeWidth={2}/>
                <Line type="monotone" dataKey="expenseAmount" stroke="#ff0000" strokeWidth={2}/>
                <Line type="monotone" dataKey="returnAmount" stroke="#FFB300" strokeWidth={2}/>

              </LineChart>
            </ResponsiveContainer>
          )}

          {chartType === 'bar' && (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={monthlyData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sellAmount" fill="#8884d8" />
                <Bar dataKey="expenseAmount" fill="#ff0000" />
                <Bar dataKey="returnAmount" fill="#FFB300" />
              </BarChart>
            </ResponsiveContainer>
          )}

{chartType === 'pie' && (
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={[
                    { name: 'Return Amount', value: totalReturnAmount },
                    { name: 'Expense Amount', value: totalExpenseAmount },
                    { name: 'Sell Amount', value: totalOrderAmount }
                  ]}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  label
                >
                  <Cell key="cell-return" fill="#FFB300" />
                  <Cell key="cell-expense" fill="red" />
                  <Cell key="cell-sell" fill="#8884d8" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}

        </div>
      )}
    </Box>
  );
};

export default Chart2;
